import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
// Composables
import useRequest from "@/composables/useRequest";
import useReadOne from "@/composables/useReadOne";

export default function useUpdate(args) {
  const { logType, endpoint, route, id, relations } = args;

  // Misc
  const router = useRouter();

  // Composables
  const { request } = useRequest();
  const { data, read } = useReadOne();

  // Data
  const isLoading = ref(false);

  // Methods
  const getData = async () => {
    isLoading.value = true;

    await read({
      endpoint: `${endpoint}.read`,
      pathParams: { id },
      queryParams: {
        ro_r: relations
      }
    });

    isLoading.value = false;
  };

  const onClickSave = async data => {
    const response = await request({
      endpoint: `${endpoint}.update`,
      pathParams: { id },
      data
    });

    if (response?.payload?.data) {
      data.value = {
        ...data.value,
        ...response.payload.data
      };
    }

    await getData();
  };

  const onClickCancel = () => {
    router.push({ name: route });
  };

  const onClickLogs = () => {
    router.push({
      name: "administration-logs",
      params: {
        logType,
        resourceId: id
      }
    });
  };

  const onClickCreate = () => {
    router.push({ name: `${route}-create` });
  };

  // Lifecycle Hooks
  onMounted(async () => {
    await getData();
  });

  return {
    data,
    isLoading,
    getData,
    onClickSave,
    onClickCancel,
    onClickLogs,
    onClickCreate
  };
}
