import { ref } from "vue";
import useRequest from "@/composables/useRequest";

export default function useReadOne() {
  // Data
  const data = ref({});
  const isLoading = ref(false);

  // Composables
  const { request } = useRequest();

  // Methods
  const read = async ({ endpoint, pathParams = {}, queryParams = {} }) => {
    isLoading.value = true;

    const response = await request({
      endpoint,
      pathParams,
      queryParams,
      showToaster: false
    });

    data.value = response?.payload?.data[0] || {};

    isLoading.value = false;
  };

  return {
    data,
    isLoading,
    // useRequest
    read
  };
}
