<template>
  <div class="box px-5 pt-5 mt-5" :class="{ 'intro-y': hasAnimation }">
    <div
      class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
    >
      <div class="flex flex-1 px-5 items-start justify-center lg:justify-start">
        <div class="w-12 h-12 image-fit relative">
          <slot name="avatar" />
        </div>

        <div class="ml-5">
          <div class="truncate sm:whitespace-normal font-medium text-lg">
            <slot name="title" />
          </div>

          <div class="text-gray-600">
            <slot name="subtitle" />
          </div>
        </div>
      </div>

      <slot name="content" />

      <div class="mt-6 lg:mt-0 flex-1 px-5 pt-5 lg:pt-0" />

      <div class="px-5">
        <slot name="status" />
      </div>
    </div>

    <slot name="tabs" />
  </div>
</template>

<script>
// Composables
import useAnimation from "@/composables/useAnimation";

export default {
  setup() {
    const { hasAnimation } = useAnimation();

    return {
      hasAnimation
    };
  }
};
</script>
