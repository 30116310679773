<template>
  <div class="flex flex-col">
    <div class="flex justify-between items-center">
      <VTitle class="flex justify-baseline items-center route-title">
        {{ $t("app.registrations", 2) }}

        <VHint class="ml-4 text-base">
          {{ $t("hints.teaching_registrations_collections") }}
        </VHint>
      </VTitle>

      <SearchField
        :show-advanced-search="showAdvancedSearch"
        :field-options="fieldOptions"
        :is-searching="isSearching"
        @input="onUpdateSearch"
        @click:search="onClickSearch"
        @click:export="onClickExport"
        @update:showAdvancedSearch="showAdvancedSearch = $event"
      >
        <VButton
          v-if="currentRoute.name !== `${route}-create`"
          :label="$t('app.add_new_registration')"
          class="btn-primary ml-3"
          @click="onClickCreate"
        />
      </SearchField>
    </div>

    <AdvancedSearch
      v-if="showAdvancedSearch"
      :show-advanced-search="showAdvancedSearch"
      :field-options="fieldOptions"
      :is-searching="isSearching"
      :class="{ 'mb-10': showAdvancedSearch }"
      @click:search="onClickSearch($event)"
      @click:export="onClickExport"
    >
      <template #content="{ index, filters }">
        <FilterForm
          :index="index"
          :options="options"
          :types="types"
          @keyup:enter="onClickSearch(filters)"
        />
      </template>
    </AdvancedSearch>

    <slot>
      <router-view />
    </slot>
  </div>
</template>

<script>
import { onMounted, provide, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
// Composables
import useRegistrationCollection from "@/composables/useRegistrationCollection";
import useSearch from "@/composables/useSearch";
// Components
import VTitle from "@/components/VTitle";
import VHint from "@/components/VHint";
import SearchField from "@/components/SearchField";
import VButton from "@/components/VButton";
import FilterForm from "@/components/templates/FilterForm";
import AdvancedSearch from "@/components/AdvancedSearch.vue";

export default {
  components: {
    VTitle,
    VHint,
    SearchField,
    VButton,
    FilterForm,
    AdvancedSearch
  },
  setup() {
    // Misc
    const router = useRouter();
    const currentRoute = useRoute();

    // DATA
    const showAdvancedSearch = ref(false);

    // Composables
    const {
      options,
      getOptions,
      searchFields,
      view_code,
      endpoint,
      route,
      types
    } = useRegistrationCollection();

    const {
      fieldOptions,
      onClickSearch,
      isSearching,
      onUpdateSearch,
      onClickExport
    } = useSearch({
      types,
      searchFields,
      route,
      endpoint
    });

    // Provide
    provide("view_code", view_code);
    provide("types", types);

    // Methods
    const onClickCreate = () => {
      router.push({ name: `${route}-create` });
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getOptions();
    });

    return {
      onClickCreate,
      // useRoute
      currentRoute,
      // useRegistrationCollection
      types,
      options,
      route,
      // useSearch
      fieldOptions,
      onClickSearch,
      isSearching,
      onUpdateSearch,
      onClickExport,
      showAdvancedSearch
    };
  }
};
</script>
