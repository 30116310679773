import { computed } from "vue";
import { useStore } from "@/store";

export default function useTeachingLogs() {
  // Misc
  const store = useStore();

  // Computed
  const isAuthorized = computed(() => {
    const isSysAdmin = store.getters["user/isSysAdmin"];
    const isAdmin = store.getters["user/isAdmin"];

    return isSysAdmin || isAdmin;
  });

  return {
    isAuthorized
  };
}
